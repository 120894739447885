import React from "react";
import {
	Select,
	FormControl,
	withStyles,
	InputLabel
} from "@material-ui/core";

const NoPaddingSelect = withStyles({
	root: {
		"&&[class*=\"MuiSelect-outlined\"]": {
			padding: 10
		}
	}
})(Select);

const MySelect = props => {
	const [labelWidth] = React.useState(0);
	React.useEffect(()=>{
		if(!props.value && props.options){
			props.onChange(props.options[0].value, props.name);
		}
	},[props.value]);

	return <FormControl variant="outlined" >
		<NoPaddingSelect
			native
			style={{minWidth:"150px"}}
			value={props.value}
			labelId="label"
			labelWidth={labelWidth}
			onChange={(event)=>{
				event.persist();    
				props.onChange(event.target.value, props.name);
			}}>
			{props.options?.map(option=>
				<option value={option.value} key={option.value}>{option.label}</option>
      )}
		</NoPaddingSelect> 
	</FormControl>;
};

export default MySelect;

