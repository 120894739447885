import React, {useState, useEffect} from "react";
import Button from "../../components/Buttons/Button";
import ButtonMaterialUi from "@material-ui/core/Button";
import { useSelector } from "react-redux";

import DehazeIcon from "@material-ui/icons/Dehaze";
import SyncIcon from "@material-ui/icons/Sync";

import "./NavBar.scss";

// import Pacientes from "../../assets/img/icons/pacientes.png";
// import PacientesOnline from "../../assets/img/pacientes_online.png";
// import Citas from "../../assets/img/icons/citas.png";
// import Mensajes from "../../assets/img/icons/mensajes.png";
// import Dietas from "../../assets/img/icons/dietas.png";
// import Premios from "../../assets/img/icons/premios.png";
// import MiRecetario from "../../assets/img/icons/recetario.png";
// import ProductosQueAmamos from "../../assets/img/amamos.png";
// import Configuracion from "../../assets/img/icons/configuracion.png";
// import RecomiendaYGana from "../../assets/img/icons/recomienda.png";
import Logo from "../../assets/img/logo.png";

import useWindowSize from "Hooks/useWindowSize";
import { Icon } from "@material-ui/core";
import RefreshButton from "components/Header/RefreshButton";
import CerrarSesionButton from "components/Header/CerrarSesionButton";

const NavBar = () => {
	const {innerWidth} = useWindowSize();

	const [collapsed, setCollapsed] = useState(innerWidth < 769 );

	useEffect(() => {
		setCollapsed( innerWidth < 769 );
	}, [innerWidth]);

	const onClick = () => {
		if(innerWidth < 769) setCollapsed(!collapsed);
	};

	return (
		<div className="container-navbar">
			<div className="logo-navbar">
				<img src={Logo} alt="logo" />

				
				{
					innerWidth < 769 &&
        <div className="button-collapsed">
        	<ButtonMaterialUi onClick={()=> {setCollapsed(!collapsed);} }><Icon style={{color:"#ffffff"}}>dehazeIcon</Icon></ButtonMaterialUi>
        </div>
				}
			</div>

			{
				!collapsed &&
          <div className={"container-routes"}>
          	<Button onClick={onClick} path="/masejet" text="Masejet" link />
          	<Button onClick={onClick} path="/mishna" text="Mishna" link />
          	
          	{/* <Button onClick={onClick} path="/recomienda" text="Recomienda y Gana" image={RecomiendaYGana} link /> */}
          	{innerWidth < 769 &&
              <>
              	<RefreshButton />
              	<CerrarSesionButton />
              </>
          	}
          </div>
			}
		</div>
	);
};

export default NavBar;
