import React,{useEffect} from "react";
import BaseTable from "../BaseTable/BaseTable";
import { useSelector, useDispatch } from "react-redux";
import {Checkbox, CircularProgress} from "@material-ui/core";
import { useImmer } from "use-immer";

import "./MultiSelectTable.scss";

export default function SelectableTable(props) {
	const { onChange, columns, type, options, selected, onSelect, onUnselect } = props;
	let { data } = props;
	const selectorData = useSelector(state => state[type.name]);
	const dispatch = useDispatch();
	const [selectedIds, setSelectedIds] = useImmer(new Set(selected));

	if(type && !data){
		data = selectorData.data;
	}
	const regularData = React.useMemo(()=>data?.filter(d=>!d._trash),[data]);


	// data = data.filter(d=>!d._trash);

	const retry = () => {
		dispatch({type:`LOAD_${type.name}`});
	};
	const onLocalSelect = cell => event => {
		event.persist();
		setSelectedIds(draft=>{
			if(event.target.checked){
				onSelect && onSelect({data:cell.row.original,index:cell.row.index});
				draft.add(cell.row.original._id);
			}else{
				onUnselect && onUnselect({data:cell.row.original,index:cell.row.index});

				draft.delete(cell.row.original._id);
			}
		});
	};
	const onGlobalSelect = (event, table) => {
		event.persist();
		setSelectedIds(draft=>{
			if(event.target.checked){
				return new Set([...draft, ...table.data.map(d=>d._id)]);


				// if(table.preFilteredRows.length===table.filteredRows.length){
				// 	draft.clear();
				// 	draft.add("*");
				// }
				// else{
				// 	return new Set([...draft, ...table.filteredRows.map(row=>row.original._id)]);
				// }

			}else{
				draft.clear();
			}
		});
	};


	useEffect(()=>{
		console.log("selected size",selectedIds.size);
		onChange && onChange([...selectedIds]);
	},[selectedIds]);

	const actionsColumn = {
		Header:function Header(table){
			console.log("total rows",table.totalRows);
			return <Checkbox
				onChange={(e)=>onGlobalSelect(e, table)}
				checked={selectedIds.size===table.totalRows}
				color="primary"
				inputProps={{ "aria-label": "primary checkbox" }}
			/>;
		},
		Cell: function SelectCheckbox({ cell }){
			return <>
				<Checkbox
					checked={selectedIds.has("*") || selectedIds.has(cell.row.original._id)}
					onChange={onLocalSelect(cell)}
					color="primary"
					inputProps={{ "aria-label": "primary checkbox" }}
				/>
				
			</>;
		},
		accessor: "id",
		id: "actions",
		disableSortBy: true,
		disableFilters: true
	};

	const finalColumns = React.useMemo(()=>[actionsColumn, ...columns]);

	return (
		<>
			{selectorData?.view?.status==="LOAD_FAILED" 
				?
				<div>LOAD FAILED<button onClick={retry}>Retry</button></div>
				:
				selectorData?.view?.status==="LOADING" 
					? 
					<CircularProgress />
					:
					<BaseTable
						columns={finalColumns}
						data={regularData}
						options={options}
						filters={props.filters}
					/>
			}
		</>
	);
}
