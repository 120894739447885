import React from "react";

import {
	FormGroup,
	FormControl,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";



const MyCheckbox = props => {
	const handleChange = name => event => {
		props.onChange({...props.value, [name.value]:event.target.checked}, props.name);
	};


	return <FormControl component="fieldset" >
		{/* <FormLabel component="legend">{props.label}</FormLabel> */}
		<FormGroup onChange={(e)=>{
			e.persist();
		}}>
			{props.options.map((o,idx)=>
				<React.Fragment key={o.value}>
					<FormControlLabel 
						control={<Checkbox color="primary" key={o.value+"c"} checked={props.value[o.value]} onChange={handleChange(o)} value={o.value} />}
						label={o.label}
					/>
				</React.Fragment>
			)}
		</FormGroup>
	</FormControl>;


};

export default MyCheckbox;

