import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

export default function Boolean(props) {
	const handleChange = event => {
		event.persist();
		props.onChange(event.target.value === "true", props.name);
	};

	return (
		<>
			<FormControl component="fieldset" disabled={props.value!==undefined && props.disableOnValue }>
				{/* <FormLabel component="legend">{props.label}</FormLabel> */}
				<RadioGroup
					color="primary"
					value={props.value ? props.value : ""}
					onChange={handleChange}
				>
					<FormControlLabel
						value={false}
						control={<Radio color="primary" checked={!props.value} />}
						label={props.falseLabel ? props.falseLabel : "No"}
					/>
					<FormControlLabel
						value={true}
						control={<Radio color="primary" checked={props.value} />}
						label={props.trueLabel ? props.trueLabel : "Si"}
					/>
				</RadioGroup>

        
			</FormControl>
		</>
	);
}
