import React from "react";
import ModalElement from "../Element/ModalElement";

const If = props => {
	return (
		<>
			{ props.formula(props.value?.[props.name[0]]) &&
				<ModalElement element={props.element.field}
					style={{margin:0}}
					onChange={props.onChange}
					onFile={props.onFile}
					value={props.value?.[props.name[1]]}
				/> 
			}
		</>
	);
};
export default If;
