import React from "react";
import Filter from "components/Filter/Filter";

// import styled from 'styled-components'
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
// A great library for fuzzy filtering/sorting items
import matchSorter from "match-sorter";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import TextCell from "../CellTypes/TextCell/TextCell";
import DefaultColumnFilter from "../FilterTypes/DefaultFilter/DefaultFilter";
import "./BaseTable.scss";
const moment = require("moment");
function fuzzyTextFilterFn(rows, id, filterValue) {
	return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

// const data=[]

function useTraceUpdate(props) {
	const prev = React.useRef(props);
	React.useEffect(() => {
		const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
			if (prev.current[k] !== v) {
				ps[k] = [prev.current[k], v];
			}
			return ps;
		}, {});
		if (Object.keys(changedProps).length > 0) {
			console.log("Changed props:", changedProps);
		}
		prev.current = props;
	});
}
// Our table component
export default React.memo(function Table(props) {
	const filterTypes = React.useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// "startWith"
			text: (rows, id, filterValue) => {
				filterValue = String(filterValue).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
				return rows.filter(row => {
					const rowValue = String(row.values[id]).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
					
					return rowValue !== undefined
						? rowValue.includes(filterValue)
						: true;
				});
			},
			daterange:(rows, id, filter) => {
				if (!filter[0] || !filter[1]) {
					return rows;
				}
				return rows.filter(row=>{
					if (moment(row.values[id]).isBetween(filter[0], filter[1] ,"days","[]") ) {
						return true;
					}
				});
			}
		}),
		[]
	);
	
	const sortTypes = React.useMemo(
		() => ({
			basic: (rowA, rowB, columnId) => {
				let a = rowA.values[columnId];
				let b = rowB.values[columnId];
				a = a ? a.toLowerCase() : a;
				b = b ? b.toLowerCase() : b;
				return a === b ? 0 : a > b ? 1 : -1;
			}
			
		}),
		[]
	);


	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
			Cell:TextCell,
			filter:"text",
			sortType: "basic"
		}),
		[]
	);

	const { columns, options, data } = props;
	const isFilterable = options && options.filter;
	const isOrderable = options && options.orderBy;
	const defaultSorting = (options && options.defaultSorting) ? options.defaultSorting : undefined ;
	const hiddenColumns = (options && options.hiddenColumns) ? options.hiddenColumns : [] ;

	const [totalRows, setTotalRows] = React.useState(data.length);
	let initialState = {
		hiddenColumns:["_id", ...hiddenColumns]
	};

	if(defaultSorting){
		initialState.sortBy = defaultSorting;
	}else{
		initialState.sortBy = [{
			id: "nombre",
			desc: false
		}];
	}

	const [filteredData, setFilteredData] = React.useState(data);

	React.useEffect(()=>{
			// console.log(props);
	},[props]);

	React.useEffect(()=>{
		if(!props.filters){
			setFilteredData(data);
		}
		setTotalRows(data.length);
	},[data]);

	React.useEffect(()=>{
		gotoPage(0);
	},[filteredData]);

	

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		pageOptions,
		page,
		state: { pageIndex },
		previousPage,
		nextPage,
		gotoPage,
		canPreviousPage,
		canNextPage,
		toggleHideColumn,
		
	} = useTable(
		{
			columns: columns,
			data: filteredData,
			defaultColumn,
			filterTypes,
			initialState,
			autoResetPage: false,
			autoResetFilters: false,
			autoResetSortBy: false,
			sortTypes:sortTypes,
			totalRows:totalRows
		},
		useFilters, // useFilters!
		useSortBy,
		usePagination
	);

	const filters = <Filter data={data} filteredData={setFilteredData} filtros={props.filters} />;
	return (
		<>
			<div>
				{props.filters && filters}
				
					{/* headerGroups.map((headerGroup,idx) => (
						<div key={idx} className="filtros-base-table">
							<div className="row-filter" {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<React.Fragment key={column.id}>
										{column.Filter && (
											<>
												{!column.disableFilters && (
													<div {...column.getHeaderProps()} style={{marginRight:"10px"}}>
														<p className="header" style={{fontWeight:"bold",marginBottom:"3px"}}>
															{column.render("Header")}
														</p>
														<div className="container-type-filter">
															{column.canFilter ? column.render("Filter") : null}
														</div>
													</div>
												)}
											</>
										)}
									</React.Fragment>
								))}
							</div>
						</div>
					))} */}
			</div>
			<div style={{ overflowX: "auto" }}>
				<table {...getTableProps()} className="table">
					<thead className="header">
						{headerGroups.map((headerGroup,idx) => (
							<tr key={idx} {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<th
										key={column.id}
										{...column.getHeaderProps(
											isOrderable ? column.getSortByToggleProps():undefined
										)}
										className={(column.render("id") === "actions") ? "actions-table" : undefined}
									>
										{column.render("Header")}
										{(isOrderable && !column.disableSortBy) && <span>
											{column.isSorted
												? column.isSortedDesc
													? <ArrowUpwardIcon  />
													: <ArrowDownwardIcon />
												: <ImportExportIcon />}
										</span>}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()} className="body">
						{page.map((row, i) => {
							prepareRow(row);
							return (
								<tr key={i} {...row.getRowProps()} className="container-element">
									{row.cells.map(cell => (
										<td key={cell.id} {...cell.getCellProps()} className={`element ${cell.column.render("id") === "actions" ? "actions-table":""}`}>
											{cell.render("Cell")}
										</td>	
									))}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<div className="container-navigation-table">
				<button
					className="button previous-page"
					onClick={() => previousPage()}
					disabled={!canPreviousPage}
				>
					Página Anterior
				</button>
				<div className="actual-page">
					Página{" "}
					<em>
						{pageIndex + 1} de {pageOptions.length}
					</em>
				</div>
				<button
					className="button next-page"
					onClick={() => nextPage()}
					disabled={!canNextPage}
				>
					Siguiente Página
				</button>
				<div style={{float:"right"}}>{props.footerComponent}</div>
			</div>
		</>
	);
});
