import React from "react";
import AllValues from "../AllValues/AllValues";
import { TextField, InputAdornment } from "@material-ui/core";

function useTraceUpdate(props) {
	const prev = React.useRef(props);
	React.showLabeluseEffect(() => {
		const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
			if (prev.current[k] !== v) {
				ps[k] = [prev.current[k], v];
			}
			return ps;
		}, {});
		if (Object.keys(changedProps).length > 0) {
			console.log("Changed props:", changedProps);
		}
		prev.current = props;
	});
}
const Text = props => {
	return (
		<React.Fragment>
			{props.element?.showHistoric && (
				<AllValues value={props.value} element={props.element} />
			)}
			<TextField
				size="small"
				variant="outlined"
				disabled={props.readOnly}
				multiline={props.multiline || props.moreRows }
				rows= {props.moreRows && 5}
				label={props.showLabel ? props.labelInput : ""}
				value={props.formula ? String(props.formula(props.value)) : props.value}
				type={props.subtype ? props.subtype : "text"}
				onChange={event => {
					let v = props.subtype==="number" ? +event.target.value : event.target.value;
					
					props.onChange(v, props.name);
				}}
				InputProps={{
					startAdornment: props.labelAdornment ? <InputAdornment position="start">{props.labelAdornment}</InputAdornment> : undefined,
				}}
				// inputProps={{
				//   style:{padding:10}
				// }}
				// inputMultiline={{
				//   style:{padding:0}
				// }}
			/>
		</React.Fragment>
	);
};

export default React.memo(Text);
