
import React, {useState, useRef,useEffect} from "react";


import {
	TextField,
	Checkbox
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {Autocomplete} from "@material-ui/lab";
import { useSelector } from "react-redux";
// const NoPaddingAutocomplete = withStyles({
//   inputRoot: {
//     '&&[class*="MuiOutlinedInput-root"]': {
//       padding: 1
//     }
//   },
//   input: {}
// })(Autocomplete);

function useTraceUpdate(props) {
	const prev = useRef(props);
	useEffect(() => {
		const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
			if (prev.current[k] !== v) {
				ps[k] = [prev.current[k], v];
			}
			return ps;
		}, {});
		if (Object.keys(changedProps).length > 0) {
			console.log("Changed props:", changedProps);
		}
		prev.current = props;
	});
}
const Text = props => {
	useTraceUpdate(props);
	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;
	let options = useSelector(eval(props.selector));
	options = options?.filter(o=> o._id && !o._trash && o.nombre ) || [];
	options = options.sort((rowA, rowB) => {
		let a = rowA.nombre;
		let b = rowB.nombre;
		a = a ? a.toLowerCase() : a;
		b = b ? b.toLowerCase() : b;
		return a === b ? 0 : a > b ? 1 : -1;
	});

	const getLabel = (id) => {
		const obj = options.find(o=>o._id===id);
		return obj ? obj.nombre : "";
	};
	const handleChange = (event, value) =>  {
		props.onChange(value, props.name);
	};
	return <Autocomplete
		id="combo-box-demo"
		size="small"
		multiple
		defaultValue={props.value}
		disableCloseOnSelect
		options={options.map(o=>o._id)}
		getOptionLabel={option => getLabel(option)}
		renderOption={(option,{selected}) => (
			<>
				<React.Fragment>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{ marginRight: 8 }}
						checked={selected}
					/>
					{getLabel(option)}
				</React.Fragment>
			</>
		)}
		style={{ width: "100%" }}
		onChange={handleChange}
		renderInput={params => (
			<>
				<TextField
					{...params}
					variant="outlined"
					fullWidth
				/>
			</>
		)}
	/>;
    
};

export default Text;



