import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker, TimePicker, DateTimePicker } from "@material-ui/pickers";
import es from "date-fns/locale/es";


export default function CalendarPicker(props) {
	return (
		<div>
			<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
				{props.element.subtype==="datetime" ?
					<DateTimePicker
						size="small"
						inputVariant="outlined"
						format="dd/MM/yyyy HH:mm"
						minutesStep={5}
						ampm={false} 
						value={props.value?props.value:null}
						onChange={date => props.onChange(date.toISOString(), props.name)}
					/>


					:
					props.element.subtype==="time" 
						?
						<TimePicker
							size="small"
							inputVariant="outlined"
							autoOk
							ampm={false} 
							minutesStep={5}
							value={props.value?props.value:null}
							onChange={date => props.onChange(date.toISOString(), props.name)} />
						:
						<DatePicker
							size="small"
							inputVariant="outlined"
							autoOk
							format="dd/MM/yyyy"
							views={["year", "month", "date"]}
							value={props.value?props.value:null}
							onChange={date => props.onChange(date.toISOString(), props.name)} />
				}

			</MuiPickersUtilsProvider>
		</div>
	);
}