import React from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
const typeSelector = (state, props) => state[props.column.type.name]?.data.filter(d=>props.value?.includes(d._id));
const makeIdsToValue = () => {
	return createSelector(
		[ typeSelector ],
		(data) => data?.reduce((acc, val)=>acc.length===0?val.nombre:acc+", "+val.nombre,"") 
	);
};


export default function RefCell({cell}){
	const idsToValue = makeIdsToValue();
	const data = useSelector(state=>idsToValue(state,cell));

	return (
		<>
			<div>{data}</div>
		</>
	);
}