import React from "react";
import {AppBar, Tabs, Tab, Typography, Box, TextField, FormLabel, FormGroup, Avatar, Icon, Button, Modal, Fade } from "@material-ui/core";

function TabPanel(props) {    
        
	const { children, value, index, ...other } = props;
  
	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	);
}

export default TabPanel;