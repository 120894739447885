import React from "react";
import { IconButton, InputAdornment, TextField, Button } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const Password = props => {
	const [showPassword, setShowPassword] = React.useState(false);
	const [password, setPassword] = React.useState(props.value);
	const [confirmPassword, setConfirmPassword] = React.useState(props.value);
	const [changing, setChanging] = React.useState(false);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};
	React.useEffect(()=>{
		setPassword(props.value);
		setConfirmPassword(props.value);
	},[props.value]);

	React.useEffect(()=>{
		if(password===confirmPassword) {
			setChanging(false);
			props.onChange(password, props.name);
			props.setValid && props.setValid(true);
		}
		else{
			props.setValid && props.setValid(false);
		}
	},[password, confirmPassword]);


	return (
		<div>
			{(props.value&&!changing)
				?
				<div>
					<div>Contraseña asignada</div>
					<Button variant="contained" color="secondary" onClick={()=>setChanging(c=>!c)}>Cambiar</Button>
				</div>
				:
				<>
					<TextField
						label="Contraseña"
						variant="outlined"
						// value={password||""}
						id="standard-adornment-password"
						type={showPassword ? "text" : "password"}
						style={{
							margin:"10px 0"
						}}
						onChange={event => {
							setPassword(event.target.value);
						}}
					/>
					{props.confirmPassword && 
						<TextField
							label="Confirmar Contraseña"
							variant="outlined"
							id="standard-adornment-password"
							type={showPassword ? "text" : "password"}
							// value={confirmPassword}
							error={password === confirmPassword ? false : true}
							onChange={event => {
								setConfirmPassword(event.target.value);
							}}
						/>
					}
				</>
			}
		</div>
	);
};

export default Password;
