import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import Element from "./index";

export default function ModalElement (props) {
	return (
		<div style={{margin:"10px",marginBottom:"20px", ...props.style}}>
			<div hidden={props.element.hidden}>
				<Typography variant="h5" component="h2">
					{props.element.label}
				</Typography>
				<Element
					{...props}
					style={{marginBottom:"20px"}}
					element={props.element}
					filterBy={props.filterBy}
					value={props.value}
					onChange={props.onChange} />
			</div>
		</div>
	);
}
// ModalElement.propTypes = {
// 	style: PropTypes.object,
// 	element: PropTypes.object.isRequired,
// 	onChange: PropTypes.function.isRequired,
// 	value: PropTypes.any,
// 	filterBy: PropTypes.any,
// };