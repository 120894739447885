import React, { useState } from "react";
import { Route, BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";

import Theme from "../Theme";
import "./Routes.scss";

import Header from "../components/Header/Header";

import NavBar from "../pages/NavBar/NavBar";
import Mishna from "../pages/Mishna/TablaMishna";
import Masejet from "../pages/Masejet/TablaMasejet";
import Login from "../pages/Login/Login";
import Forgot from "../pages/Login/Olvide";
import Reset from "../pages/Login/Reset";


import PrivateRoute from "./PrivateRoute/PrivateRoute";
import PublicRoute from "./PublicRoute/PublicRoute";
// import usePrevious from "Hooks/usePrevious";

const Routes = () => {

	let RNavBar = window.location.pathname === "/login" || window.location.pathname.startsWith("/reset") || window.location.pathname === "/forgot"  ? null : <NavBar />;
	return (
		<>
			<div className="container">
				<PublicRoute exact path="/login" component={Login} />
				<PublicRoute exact path="/forgot" component={Forgot} />
				<PublicRoute exact path="/reset/:token/:idNutriologo" component={Reset} />

				<div className={(window.location.pathname === "/login"||window.location.pathname === "/forgot") ? "": "container-app"}>
					{RNavBar}

					<PrivateRoute exact path="/" component={Masejet} />
					<PrivateRoute path="/mishna" component={Mishna} />
					<PrivateRoute path="/masejet" component={Masejet} />

				</div>
			</div>
		</>
	);
};

export default Routes;
