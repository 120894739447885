import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import { MASEJET } from "store/constants";

const Premio = props => {
	const elements = [
		{
			type:"ref",
			name:"id_masejet",
			label:"Masejet",
			selector:(state)=>state.MASEJET.data
		},
		{
			type: "text",
			name:"orden",
			label:"Orden",
			subtype: "number",
		},
		{
			type:"text",
			name:"titulo",
			label:"Título"
		},
		{
			type:"text",
			name:"subtitulo",
			label:"Subtítulo"
		},
		{
			type: "text",
			name: "donado_por",
			label: "Donado por",
			multiline: true
		},
		{
			type: "file",
			name: "archivo",
			label: "PDF",
			accept: "application/pdf"
		},
		{
			type: "file",
			name: "audio",
			label: "Audio",
			accept: "audio/*"
		}
	];
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} onFile={props.onFile} data={props.data}></ModalForm>
		</>
	);
};

export default Premio;
