
import { call, put } from "redux-saga/effects";
import api from "api";
import { takeEvery, takeLatest, all } from "redux-saga/effects";
import { LOAD, INSERT, UPDATE, REMOVE, makeStatusFromType } from "../constants";
import {
	LOADING,
	LOADED,
	LOAD_FAILED,

	INSERTING,
	INSERT_FAILED,
	INSERTED,
	UPDATING,
	UPDATED,
	UPDATE_FAILED,
	DELETING,
	DELETE_FAILED,
	TRASH,
	UNTRASH
} from "../constants";
const { post, get, put: update, remove, upload } = api;
export const loadRequest = (type) =>
	function* load() {
		yield takeLatest(LOAD + type.name, function* load() {
			const status = makeStatusFromType(type.name);
			try {
				yield put({ type: status, payload: {status: LOADING} });
				const response = yield call(get, type.url);
				yield put({ type: "SET_" + type.name, payload: response.data });
				yield put({ type: status, payload: {status: LOADED} });
			} catch (e) {
				console.error(e);
				yield put({ type: status, payload: {status: LOAD_FAILED} });
			}
		});
	};
export const insertRequest = (type) =>
	function* insert() {
		yield takeEvery(INSERT + type.name, function* insert(action) {
			const status = makeStatusFromType(type.name);
			try {
				yield put({ type: status, payload: {status: INSERTING} });
				const {files, ...payload} = action.payload;
				let response = yield call(post, type.url, payload);
				if(files && files.length>0){
					response = yield all(files.map((file) => call(upload, `${type.url}/${response.data.data._id}/${file.name}`, file.file)));
					response = response[response.length-1];
				}
				yield put({ type: "INSERT_L_" + type.name, payload: response.data.data });
				yield put({ type: status, payload: {status:INSERTED, id:response.data.data._id} });
				if(action.callback){
					yield call(action.callback,response.data);
				}
			} catch (e) {
				yield put({ type: status, payload: {status: INSERT_FAILED, errorMsg: e} });
				if(action.errorCallback){
					yield call(action.errorCallback,e);
				}
			}
		});
	};


export const updateRequest = (type) =>
	function* updateRequest() {
		const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;
		yield takeEvery(UPDATE + name, function* insert(action) {
			const status = makeStatusFromType(type.name);
			const url = type.url + "/" + action.payload._id;
			try {
				yield put({ type: status, payload: {status: UPDATING} });
				const {files, ...payload} = action.payload;
				const {_id, parent_id, ...realPayload} = payload;
        
				let response;
				if(Object.keys(realPayload).length>0){
					response = yield call(update, url, realPayload);
				}
				if(files && files.length>0){
					response = yield all(files.map((file) => call(upload, `${url}/${file.name}`, file.file)));
					response = response[response.length-1];
				}
				if(response){
					yield put({ type: "UPDATE_L_" + name, payload: response.data.data });
					yield put({ type: status, payload: {status: UPDATED, id: response.data.data._id} });
				}else{
					yield put({ type: status, payload: {status: UPDATED} });
				}
				if(action.callback){
					yield call(action.callback,response?.data || {});
				}
			} catch (e) {
				yield put({ type: status, payload: {status: UPDATE_FAILED, errorMsg: e} });
				if(action.errorCallback){
					yield call(action.errorCallback,e);
				}

			}
		});
	};
export const trashRequest = (type) =>
	function* trash() {
		yield takeEvery(TRASH + type.name, function* trash(action) {
			const status = makeStatusFromType(type.name);
			const url = type.url + "/" + action.payload._id;
			try {
				yield put({ type: status, payload: {status: UPDATING} });

				const payload = {_trash: true};
				let response = yield call(update, url, {...payload});
				yield put({ type: "UPDATE_L_" + type.name, payload: response.data.data });
				yield put({ type: status, payload: {status: LOADED, id: response.data.data._id} });
			} catch (e) {
				yield put({ type: status, payload: {status: UPDATE_FAILED} });
			}
		});
	};
export const untrashRequest = (type) =>
	function* insert() {
		yield takeEvery(UNTRASH + type.name, function* insert(action) {
			const status = makeStatusFromType(type.name);
			const url = type.url + "/" + action.payload._id;
			try {
				yield put({ type: status, payload: {status: UPDATING} });
				const payload = {_trash: false};
				let response = yield call(update, url, payload);
				yield put({ type: "UPDATE_L_" + type.name, payload: response.data.data });
				yield put({ type: status, payload: {status: LOADED, id: response.data.data._id} });
			} catch (e) {
				yield put({ type: status, payload: {status: UPDATE_FAILED} });
			}
		});
	};
export const removeRequest = (type) =>
	function* insert() {
		yield takeEvery(REMOVE + type.name, function* insert(action) {
			const status = makeStatusFromType(type.name);
			const url = type.url+"/" + action.payload._id;
			try {
				yield put({ type: status, payload: {status: DELETING} });
				yield call(remove, url);
				yield put({ type: "UNSET_" + type.name, payload: action.payload._id });
				yield put({ type: status, payload: {status: LOADED} });
			} catch (e) {
				yield put({ type: status, payload: {status: DELETE_FAILED} });
			}
		});
	};

