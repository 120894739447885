import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import ModalMasejet from "./ModalMasejet";
import { useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import { MASEJET } from "store/constants";

const Masejet = () => {
	const data = useSelector(state => state[MASEJET.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Nombre",
				accessor: "nombre"
			},
			{
				Header: "Donado por",
				accessor: "donado_por"
			}
		],
		[]
	);

	return (
		<>
			<Header title="Masejtot" />

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				filters={columns}
				type={MASEJET}
				modal={ModalMasejet}
				options={{filter: true,orderBy:true}}
			/>
		</>
	);
};

export default Masejet;
