export const READY = "READY";
export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const LOAD_FAILED = "LOAD_FAILED";

export const SAVING = "SAVING";
export const SAVED = "SAVED";
export const SAVE_FAILED = "SAVE_FAILED";

export const INSERTING = "INSERTING";
export const INSERTED = "INSERTED";
export const INSERT_FAILED = "INSERT_FAILED";

export const UPDATING = "UPDATING";
export const UPDATED = "UPDATED";
export const UPDATE_FAILED = "UPDATE_FAILED";

export const DELETING = "DELETING";
export const DELETED = "DELETED";
export const DELETE_FAILED = "DELETE_FAILED";

export const LOAD = "LOAD_";
export const INSERT = "INSERT_";
export const UPDATE = "UPDATE_";
export const REMOVE = "REMOVE_";
export const TRASH = "TRASH_";
export const UNTRASH = "UNTRASH_";

export const MASEJET= {url:"masejet", name:"MASEJET", label:"Masejet"};
export const MISHNA= {url:"mishna", name:"MISHNA", label:"Mishna"};

export const makeStatusFromType = type => type.toUpperCase() + "_STATUS";
