import React, {useMemo, useState, useCallback} from "react";
import {useDropzone} from "react-dropzone";
import {Button, IconButton} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';

const baseStyle = {
	flex: 1,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "20px",
	borderWidth: 2,
	borderRadius: 2,
	borderColor: "#eeeeee",
	borderStyle: "dashed",
	backgroundColor: "#fafafa",
	color: "#bdbdbd",
	outline: "none",
	transition: "border .24s ease-in-out"
};

const activeStyle = {
	borderColor: "#2196f3"
};

const acceptStyle = {
	borderColor: "#00e676"
};

const rejectStyle = {
	borderColor: "#ff1744"
};
const filesString = "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*";
export default function StyledDropzone(props) {
	const [changing, setChanging] = useState(false);
	const onDrop = useCallback(acceptedFiles => {
		// Do something with the files
		if(acceptedFiles.length>0)
			props.onFile({files: acceptedFiles}, props.name, props.single);
	}, []);
	const remove=function(){

	};

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject
	} = useDropzone({
		accept: props.accept ? props.accept : (props.acceptFiles ? filesString : "image/*"),
		onDrop
	});

	const style = useMemo(() => ({
		...baseStyle,
		...(isDragActive ? activeStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isDragActive,
		isDragReject
	]);

	return (
		<div>
			{props.value?.tipo?.includes("image") 
				? 
				<div style={{height:"100%"}}>
					<img style={{ width: "200px", borderRadius: "50%", height: "200px", margin: "auto"}} src={`${process.env.REACT_APP_FILE_URL}${props.value.path}`} />
				</div>
				:
        props.value?.tipo
			}
			{(props.value && props.download) &&
				<a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_FILE_URL}${props.value.path}`} download><IconButton><GetAppIcon /></IconButton></a>

			}
			{(!props.value || changing) ?
				<>
					<div className="container">
						<div {...getRootProps({style})}>
							<input {...getInputProps()} />
							<p>Arrastra tu archivo, o da click para seleccionar</p>
						</div>
					</div>
					{props.files?.filter(f=>f.name===props.name).map((file,idx)=>
						<><div key={file.file.name}>{file.file.name}</div><button onClick={()=>props.remove(idx,"files")}>x</button></>
          )}
				</>
				:
				<Button variant="contained" color="primary" size="small" onClick={()=>setChanging(true)}>Cambiar</Button>
			}

      
		</div>
    
	);
}