import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";

const Premio = props => {
	const elements = [
		{
			type:"text",
			name:"nombre",
			label:"Nombre"
		},
		{
			type: "text",
			name: "donado_por",
			label: "Donado por",
			multiline: true
		}
	];
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data}></ModalForm>
		</>
	);
};

export default Premio;
