import rootSaga from "../sagas/index";
import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import crudReducer from "./crudReducer";
import {
	MISHNA,
	MASEJET
} from "../constants";

var emptyReducer = () => ({});


const mishna = crudReducer(emptyReducer, MISHNA.name, {
	data: [],
	view: {}
});
const masejet = crudReducer(emptyReducer, MASEJET.name, {
	data: [],
	view: {}
});

const sagaMiddleware = createSagaMiddleware();

const app = combineReducers({
	[MISHNA.name]:mishna,
	[MASEJET.name]: masejet
});

const composerEnhancers = composeWithDevTools({
	name: "Redux",
	realtime: true,
	trace: true,
	traceLimit: 25
});
const enhancer = composerEnhancers(
	applyMiddleware(sagaMiddleware),
);
const store = createStore(app, enhancer);

sagaMiddleware.run(rootSaga);

export default store;
