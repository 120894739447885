import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { withStyles, IconButton } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import es from "date-fns/locale/es";
const CustomDatePicker = withStyles({
	input: {
		color:"gray"
	}
})(DatePicker);
// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function DateRange(props) {
	const { column: { filterValue = [], setFilter } } = props;
	
	return (
		<div style={{display: "flex"}}>
			<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
				<>
					<DatePicker
						style={{paddingRight:"5px",width:"120px"}}
						inputProps={{style:{color:"gray"}}}
						size="small"
						inputVariant="outlined"
						autoOk
						emptyLabel="De"
						format="dd/MM/yyyy"
						views={["year", "month", "date"]}
						value={filterValue[0] || null}
						onChange={(date) => {
							setFilter([date ? new Date(date).toISOString() : undefined, filterValue[1]]);
						}}
					/>

					<DatePicker
						style={{width:"120px"}}
						size="small"
						inputProps={{style:{color:"gray"}}}
						inputVariant="outlined"
						emptyLabel="Hasta"
						autoOk
						format="dd/MM/yyyy"
						views={["year", "month", "date"]}
						value={filterValue[1] || null}
						onChange={date => {
							setFilter([filterValue[0], date ? new Date(date).toISOString() : undefined]);
						}}
					/>
					{(filterValue[0] || filterValue[1]) && <IconButton style={{padding:0}} onClick={()=>setFilter(() => [undefined, undefined])}><ClearIcon /></IconButton>}
				</>
			</MuiPickersUtilsProvider>
		</div>
	);
}
export default DateRange;