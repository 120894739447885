import React from "react";
import { TextField } from "@material-ui/core";
// Define a default UI for filtering
export default function DefaultColumnFilter(props) {
	const {
		column: { filterValue, setFilter }
	} = props;
	return (
		<TextField
			size="small"
			value={filterValue || ""}
			onChange={e => {
				setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
			}}
			variant="outlined"
		/>
	);
}
