import produce,{original} from "immer";
import * as immutable from "object-path-immutable";
const _ = require("lodash");


var baseReducer = (reducers, name, base) =>
	produce((draft, action) => {
		switch (action.type) {
		case `${name}_STATUS`:
			draft.view = {...draft.view, ...action.payload};
			break;
		case `SET_${name}`: {
			draft.data = action.payload;
			break;
		}
		case `UNSET_${name}`: {
			const id = action.payload;
			draft.data = removeElement(draft.data, id);
			break;
		}
		case `INSERT_L_${name}`: {
			draft.data.push(action.payload);
			break;
		}
		case `UPDATE_L_${name}`: {
			const id = action.payload._id;
			if(Array.isArray(draft.data)){
				let idx = getIdxElement(draft.data, id);
				if (idx > -1) draft.data[idx]={...draft.data[idx], ...action.payload};
			}
			else{
				draft.data = {...draft.data, ...action.payload};
			}
        
			break;
		}
		default:
			if(Array.isArray(reducers))reducers.map(reducer=>reducer(draft, action));
			else reducers(draft, action);
			break;
		}
	}, base);
  
export const makeSubReducer = (type) => (draft, action) => {
	const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;

	switch (action.type) {
    
	case `${name}_STATUS`:
		draft.view = action.payload;
		return;
	case `INSERT_L_${name}`: {
		if(Array.isArray(draft.data)){
			const idx = getIdxElement(draft.data,action.payload.parent_id);
			if(!draft.data[type.key])draft.data[idx][type.key]=[];
			draft.data[idx][type.key].push(action.payload);
		}else{
			if(!draft.data[type.key])draft.data[type.key]=[];
			draft.data[type.key].push(action.payload);
		}
		break;
	}
	case `UNSET_${name}`: {
		const id = action.payload._id;
		const parent_id = action.payload.parent_id;

		if(Array.isArray(draft.data)){
			const idx = getIdxElement(draft.data[type.key], parent_id);
			draft.data[idx][type.key] = removeElement(_.get(draft.data[idx], [type.key]), id);
		}else{
			draft.data[type.key] = removeElement(draft.data[type.key], id);
		}
		break;
	}
	case `UPDATE_L_${name}`: {
		const id = action.payload._id;

		if(Array.isArray(draft.data)){
			const parent_id = action.payload.parent_id;

			let parent_idx = getIdxElement(draft.data, parent_id);
			let idx = getIdxElement(_.get(draft.data[parent_idx],type.key), id);

			return immutable.set(draft.data, parent_idx+"."+type.key+"."+idx, action.payload);

			// if (idx > -1) draft.data[parent_idx][type.key][idx]={...draft.data[parent_idx][type.key][idx], ...action.payload};
		}
		else{

			let idx = getIdxElement(draft.data[type.key], id,action);
			draft.data[type.key][idx] = {...draft.data[type.key][idx], ...action.payload};
		}
      
		break;
	}
	default:
		break;
	}
};

// return immutable.set(state, name, value);


const removeElement = (arr, id) => arr.filter(element => element._id !== id);
const getIdxElement = (arr, id) => arr.findIndex(element => element._id === id);

export default baseReducer;
