import React, { useState, useEffect } from "react";
import DefaultFilter from "components/Table/FilterTypes/DefaultFilter/DefaultFilter";
import DateRange from "components/Table/FilterTypes/DateRange/DateRange";
import SelectFilter from "components/Table/FilterTypes/SelectFilter/SelectFilter";
import RefFilter from "components/Table/FilterTypes/RefFilter/RefFilter";
// import RefFilter from "./RefFilter";
import "./Filter.scss";
const moment = require("moment");
var e = React.createElement;


const f = {
	text: (rows, id, filterValue) => {
		if (filterValue==="")return rows;
		filterValue = String(filterValue).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		return rows.filter(row => {
			let rowValue = row[id];
			if(rowValue===undefined)return false;
			rowValue = String(rowValue).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
			return rowValue !== undefined
				? rowValue.includes(filterValue)
				: true;
		});

	},
	daterange:(rows, id, filter) => {
		console.log(filter);
		if (!filter[0] || !filter[1]) {
			return rows;
		}
		return rows.filter(row=>{
			if (!row[id] || !moment(row[id]).isValid())return false;
			if (moment(row[id]).isBetween(filter[0], filter[1] ,"days","[]") ) {
				return true;
			}
		});
	}
};
const m = {
	daterange: DateRange,
	select: SelectFilter,
	ref: RefFilter
};
const Filter = props => {
	const [filterData, setFilterData] = useState({});

	const calcFilter = (filtro) => (val) => {
		if(val===undefined)val="";
		setFilterData((state)=>({...state, [filtro.accessor]:val}));
	};

	useEffect(()=>{
		let newData = props.data;
		Object.entries(filterData).map(([key,val])=>{
			let filtro = props.filtros.find(f=>f.accessor===key);
			newData = f[filtro.filter] ? f[filtro.filter](newData, filtro.accessor, val) : f.text(newData, filtro.accessor, val);
		});
		props.filteredData(newData);
	},[filterData,props.data]);

	const FilterForType = (filtro) =>{
		if(filtro.Filtro)return filtro.Filtro;
		return m[filtro.filter]? m[filtro.filter]: DefaultFilter;
	};

	

	return (
		<div className="filtros-base-table">
			<div className="row-filter">
				{props.filtros.map(filtro => (
					<React.Fragment key={filtro.accessor}>
						<div style={{marginRight:"10px"}}>
							<p className="header" style={{fontWeight:"bold",marginBottom:"3px"}}>
								{filtro.Header}
							</p>
							<div className="container-type-filter">
								{e(FilterForType(filtro), {
									column:{
										setFilter: calcFilter(filtro), 
										filterValue: filterData[filtro.accessor],
										preFilteredRows: props.data,
										...filtro
									},
									
								})}
							</div>
						</div>
					</React.Fragment>
				))}
			</div>
		</div>
	);
};

export default Filter;
