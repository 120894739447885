import store from "store/reducers/index";
import api from "./index";
export function login(token){
	window.localStorage.setItem("mishnayot:admin", token);
	init();
}
export function init(){
	const token = window.localStorage.getItem("mishnayot:admin");
	console.log(token);
	if(token){
		api.init(token);

		store.dispatch({type:"SET_SESSION",payload:{logged:true}});
		const loadData = [
			"MISHNA",
			"MASEJET"
		];
		loadData.map((key)=>store.dispatch({ type: `LOAD_${key}` }));
	}
	else{
		store.dispatch({type:"SET_SESSION",payload:{logged:false}});
	}
}
export function refresh(key){
	if(key){
		store.dispatch({ type: `LOAD_${key.name}` });
		return;
	}
	const refreshData = [
		"MISHNA",
		"MASEJET"
	];
	refreshData.map((key)=>store.dispatch({ type: `LOAD_${key}` }));
}

export function parseJwt (token) {
	var base64Url = token.split(".")[1];
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	var jsonPayload = decodeURIComponent(atob(base64).split("").map(function(c) {
		return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(""));

	return JSON.parse(jsonPayload);
}

export default {login,init, parseJwt};