
const _ = require("lodash");

const getData = (element, data) => {
	if(Array.isArray(element.name)){
		if(data){
			const a = element.name.reduce(
				(acc, name) => ({ ...acc, [name]: _.get(data, name) }),
				{}
			);
			return a;
		}else{
			return undefined;
		}
    
	}
	else if (element.type === "group"){
		const a= element.fields.reduce(
			(acc, field) => ({ ...acc, [field.name]: getData(field, data) }),
			{}
		);
		return a;
	}
	else if (element.type === "formula")
		return element.values.map(field =>
			data ? data[field] : undefined
		);
	else if (element.type === "allValues") {
		return {
			filterBy: data ? data[element.filterBy] : undefined
		};
	} else {
		if(data){
			const value = _.get(data,element.name);
			if(value) return value;
		}
		if(element.default)return element.default;
		return undefined;
		// return data ? _.get(data,element.name) ? _.get(data,element.name) : undefined
	}
};
export default getData;