import React, {useState} from "react";
import BaseTable from "../BaseTable/BaseTable";
import { Grid, CircularProgress, Button } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import NewButton from "../../Buttons/NewButton/NewButton";
import SaveModal from "../../SaveModal/SaveModal";
import { useDispatch } from "react-redux";
import swal from "@sweetalert/with-react";
import {get} from "api/index";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "App.css";

export default function CrudTable(props) {
	const {
		data,
		type,
		onInsert,
		columns,
		modal,
		options = {}
	} = props;
	const [openedModal, setOpenModal] = useState(false);
	const [showTrash, setShowTrash] = useState(false);
	const [selectedData, setSelectedData] = useState();
	const [forceNew, setForceNew] = useState(false);
	const dispatch = useDispatch();
	const canInsert = !options.disableInsert;
	const canEdit = !options.disableEdit;
	const canDelete = !options.disableDelete;

	const handleTrash = (cell) => {
		swal({
			title: props.archivarMessageTitle ? props.archivarMessageTitle(cell) :"Confirmación",
			text: props.archivarMessageBody ? props.archivarMessageBody(cell) : `¿Deseas enviar a tu ${props.type.label} ${cell.row.original.nombre ? cell.row.original.nombre: ""} a la papelera?`,
			icon: "warning",
			buttons: ["Cancelar","Confirmar"],
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;
					dispatch({type:`TRASH_${name}`,payload:{_id:cell.row.original._id, parent_id:props._id}});
				}
			});
	};
	const handleEdit = async (cell) => {
		if(props.editAction){
			props.editAction(cell);
		}else{
			let data = cell.row.original; 
			if(options.loadBeforeEdit){
				let response = await get(type.url+"/"+cell.row.original._id);
				data = response.data;
			}
			setSelectedData(data);
			setOpenModal(true);
		}
		
	};
	const retry = () => {
		const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;
		dispatch({type:`LOAD_${name}`});

	};
	const handleNew = async () => {
		if(props.insertAction){
			props.insertAction();
		}
		else{
			setSelectedData(null);
			setOpenModal(true);
		}
	};
	const handleDelete = (cell) => {
		swal({
			title: props.eliminarMessageTitle ? props.eliminarMessageTitle(cell) :"Confirmación",
			text: "Al confirmar se eliminará toda la información de tu " + props.type.label + " " + (cell.row.original.nombre || ""),
			icon: "warning",
			buttons: ["Cancelar","Confirmar"],
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;
					dispatch({type:`REMOVE_${name}`,payload:{_id:cell.row.original._id, parent_id:props._id}});
				}
			});
	};
	const handleUntrash = (cell) => {
		const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;
		dispatch({type:`UNTRASH_${name}`,payload:{_id:cell.row.original._id, parent_id:props._id}});
	};
	const actionsColumn = React.useMemo(()=>({
		Header: (
			<>
				{canInsert && 
					<NewButton disabled={props.maxRows<=data?.length && props.maxRows!==-1} style={{float:"right"}} onClick={handleNew}>
						{props.addLabel ?props.addLabel : `Añadir ${props.type.label}`}
					</NewButton>
				}
			</>
		),
		Cell: function ActionsColumn({ cell }){
			return <Grid
				container
				direction="row"
				justify="flex-end"
				alignItems="center"
			>
				{props.actions && props.actions.map(a=>a({cell, setSelectedData, setOpenModal, setForceNew}))}
				{canEdit && 
					<Button variant="contained" style={{background:"#F8F8F8",color:"#3B4C4B", margin:"5px"}} onClick={()=>handleEdit(cell)}>Editar</Button>
				}
				{canDelete && (
					<>
						{(props.canDelete && props.canDelete(cell) ? <></> : 
							<Button variant="contained"  color="secondary"  onClick={()=>handleTrash(cell)}>Archivar</Button>
						)
						}
					</>
				)}
			</Grid>;
		},
		accessor: "id",
		id: "actions",
		filter: false,
		disableSortBy: true,
		disableFilters: true
	}),[props.data, props.maxRows]);
	
	const trashTableColumns = React.useMemo(()=>({
		Cell: function TrashColumn({ cell }){
			return <Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<>
					<Button variant="contained" color="primary" style={{margin:"5px"}} onClick={()=>handleUntrash(cell)}>Desarchivar</Button>

					{canDelete && (
						<Button variant="contained" color="secondary" onClick={()=>handleDelete(cell)}>Eliminar</Button>
					)}
				</>
			</Grid>;
		},
		accessor: "id",
		id: "actions",
		filter: false,
		disableSortBy: true,
		disableFilters: true,
	}),[]);
	const trashIconColumn = React.useMemo(()=>({
		Cell: function DeletedColumn (){
			return <DeleteOutlineIcon />;
		},
		id: "icon",
		filter: false,
		disableSortBy: true,
		disableFilters: true
	}),[]);

	const finalColumns = React.useMemo(()=>[{
		Header: "id",
		accessor: "_id",
		disableFilters:true
	},...columns, actionsColumn]
	,[props]);
	const regularData = React.useMemo(()=>data?.filter(d=>!d._trash),[data]);
	const PapeleraComponent = React.useMemo(()=><Button variant="contained" color="secondary" style={{float:"right",width:"200px"}} onClick={()=>setShowTrash(true)}>{props.papeleraLabel ? props.papeleraLabel : "Papelera"}</Button>,[]);

	return (
		<>
			{
				props.status.status==="LOAD_FAILED"
					?
					<div>LOAD FAILED<button onClick={retry}>Retry</button></div>
					:
					props.status.status==="LOADING"
						?
						<CircularProgress />
						:
						<>
							{!showTrash && 
							<BaseTable
								{...props}
								filters={props.filters}
								columns={finalColumns}
								data={regularData}
								options={options}
								footerComponent={PapeleraComponent}
							/>
							}
					
							{showTrash && 
								<>
									<Button variant="contained" color="secondary" onClick={()=>setShowTrash(false)} startIcon={<ArrowBackIcon />}> Regresar</Button>

									<BaseTable
										{...props}
										columns={[trashIconColumn,{
											Header: "id",
											accessor: "_id",
											disableFilters:true
										}, ...columns, trashTableColumns]}
										data={data?.filter(d=>d._trash)}
										options={options}
									/>
								</>
							}
						</>
			}

			{openedModal && 
			<SaveModal 
				{...props.options} 
				fullWidth={props.fullWidth} 
				parent_id={props._id} 
				onInsert={onInsert} 
				modal={modal} 
				forceNew={forceNew}
				type={type} 
				status={props.status} 
				data={selectedData} 
				handleModal={()=>{
					setOpenModal(false);
					const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;
					dispatch({
						type: `${name}_STATUS`,
						payload: {status:"LOADED"}
					});
				}} />}
		</>
	);
}
