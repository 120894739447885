
import React,{useState} from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
 
const filterAndMap = (p)=> createSelector(
	[ eval(p.element.selector) ],
	(data) => data.filter(data[p.element.filterBy]===p.value.filterBy),
	(data) => data.map(data=>data[p.element.item])
);

export default (props) => {
	const [isVisible, setVisible] = useState(false);

    

	const values = useSelector(filterAndMap(props));
	const visible=()=>{
		setVisible(true);
	};
	const hideVisible=()=>{
		setVisible(false);
	};
	return(<React.Fragment>
		<div
			//   onMouseEnter={visible}
			//   onMouseLeave={hideVisible}
		>
          Hover Me
		</div>
        
		{isVisible &&
            <React.Fragment>
            	{values.map((value,idx)=>
            		<div key={idx}>{value}</div>
            	)}
            </React.Fragment>
		}
	</React.Fragment>);
};