import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { MASEJET } from "../constants";

const loadMasejtot = loadRequest(MASEJET);
const insertMasejet = insertRequest(MASEJET);
const updateMasejet = updateRequest(MASEJET);
const trashMasejet = trashRequest(MASEJET);
const untrashMasejet = untrashRequest(MASEJET);
const removeMasejet = removeRequest(MASEJET);

function* rootSaga() {
	yield all([
		loadMasejtot(),
		insertMasejet(),
		updateMasejet(),
		trashMasejet(),
		untrashMasejet(),
		removeMasejet()
	]);
}
export default rootSaga;