import React from "react";
import {
	TextField,
	FormGroup,
	FormControl,
	FormControlLabel,
	Checkbox
} from "@material-ui/core";

const MyCheckbox = props => {
	const handleChange = name => event => {
		props.onChange({
			...props.value,
			[name]: {
				value: event.target.checked,
				detail: props.value[name] ? props.value[name].detail : ""
			}
		}, props.name);
	};
	const handleTextChange = (event, name) => {
		props.onChange({
			...props.value,
			[name]: {
				value: props.value[name].value,
				detail: event.target.value
			}
		}, props.name);
	};
	return (
		<FormControl component="fieldset">
			{/* <FormLabel component="legend">{props.label}</FormLabel> */}
			<FormGroup
				onChange={e => {
					e.persist();
				}}
			>
				{props.options.map(option => (
					<React.Fragment key={option.value}>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									onChange={handleChange(option.value)}
									checked={props.value[option.value].value}
									value={option.value}
								/>
							}
							label={option.label}
						/>
						{props.value && props.value[option.value] && props.value[option.value].value ? (
							<TextField
								style={{ width: "100%" }}
								variant="outlined"
								label={props.labelInput ? props.labelInput : "Especifica"}
								value={props.value[option.value].detail}
								onChange={e => handleTextChange(e, option.value)}
							></TextField>
						) : (
							<></>
						)}
					</React.Fragment>
				))}
			</FormGroup>
		</FormControl>
	);
};

export default MyCheckbox;
