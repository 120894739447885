import React from "react";
import { Button, Icon } from "@material-ui/core";

const NewButton = props => {
	return (
		<>
			<Button onClick={props.onClick} color="primary" variant="contained" {...props} >
				{props.children}
				{/* <>
          <Icon color="primary">add</Icon>
          <p style={{ color: "#ffffff" }}>Nuevo</p>
        </> */}
			</Button>
		</>
	);
};

export default NewButton;
