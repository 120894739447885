import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import ModalMishna from "./ModalMishna";
import { useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import { MISHNA, MASEJET } from "store/constants";
import RefCell from "components/Table/CellTypes/RefCell/RefCell";

const Mishnayot = () => {
	const data = useSelector(state => state[MISHNA.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Masejet",
				accessor: "id_masejet",
				type: MASEJET,
				Cell: RefCell,
			},
			{
				Header: "Orden",
				accessor: "orden"
			},
			{
				Header: "Título",
				accessor: "titulo"
			},
			{
				Header: "Subtítulo",
				accessor: "subtitulo"
			},
			{
				Header: "Donado por",
				accessor: "donado_por"
			},
		],
		[]
	);
	const filters = React.useMemo(
		() => [
			{
				Header: "Masejet",
				accessor: "id_masejet",
				filter: "ref",
				type:MASEJET
			},
			{
				Header: "Orden",
				accessor: "orden"
			},
			{
				Header: "Título",
				accessor: "titulo"
			},
			{
				Header: "Subtítulo",
				accessor: "subtitulo"
			},
			{
				Header: "Donado por",
				accessor: "donado_por"
			},
		],
		[]
	);

	return (
		<>
			<Header title="Mishnayot" />

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				filters={filters}
				type={MISHNA}
				modal={ModalMishna}
				options={{filter: true,orderBy:true}}
			/>
		</>
	);
};

export default Mishnayot;
