
import React, {useState, useEffect} from "react";


import {
	TextField,
	Checkbox, withStyles
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {Autocomplete} from "@material-ui/lab";
import { useSelector } from "react-redux";

const NoPaddingAutocomplete = withStyles({
	inputRoot: {
		"&&[class*=\"MuiOutlinedInput-root\"] $input": {
			padding: 1
		}
	}
})(Autocomplete);

const Ref = props => {
	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;
	let options = useSelector(eval(props.selector));
	options = options?.filter(o=> o._id && !o._trash && o.nombre );
	options = options.sort((rowA, rowB) => {
		let a = rowA.nombre;
		let b = rowB.nombre;
		a = a ? a.toLowerCase() : a;
		b = b ? b.toLowerCase() : b;
		return a === b ? 0 : a > b ? 1 : -1;
	});

	if(props.extraItems){
		options = [...props.extraItems, ...options];
	}
	const getLabel = (id) => {
		const obj = options?.find(o=>o._id===id);
		return obj ? obj.nombre : "";
	};

	const handleChange = (event, value) =>  {
		if(value===null)value="";
		props.onChange(value, props.name);
	};
	// const a = React.useMemo(()=>
	// 	<React.Fragment>
	// 		<Checkbox
	// 			icon={icon}
	// 			checkedIcon={checkedIcon}
	// 			style={{ marginRight: 8 }}
	// 			checked={selected}
	// 		/>
	// 		{getLabel(option)}
	// 	</React.Fragment>
	// ,[])
	return <NoPaddingAutocomplete
		id="combo-box-demo"
		openOnFocus
		hidden={props.hidden}
		value={props.value || ''}
		options={options?.map(o=>o._id) ||[]}
		getOptionLabel={option => getLabel(option)}
		// renderOption={(option,{selected}) => a}
		style={{ width: "100%" }}
		onChange={handleChange}
		renderInput={params => (
			<>
				<TextField
					{...params}
					variant="outlined"
					fullWidth
				/>
			</>
		)}
	/>;
    
};

export default Ref;



