import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { MISHNA } from "../constants";

const loadMishnayot = loadRequest(MISHNA);
const insertMishna = insertRequest(MISHNA);
const updateMishna = updateRequest(MISHNA);
const trashMishna = trashRequest(MISHNA);
const untrashMishna = untrashRequest(MISHNA);
const removeMishna = removeRequest(MISHNA);

function* rootSaga() {
	yield all([
		loadMishnayot(),
		insertMishna(),
		updateMishna(),
		trashMishna(),
		untrashMishna(),
		removeMishna()
	]);
}
export default rootSaga;